import React from 'react';
import Legend from 'components/molecules/Legend';

const StatusLegend: React.VFC<{ className?: string }> = (props) => {
    return (
        <Legend
            className={props.className}
            data={[
                { color: 'green', text: '正常' },
                { color: 'yellow', text: '問題あり' },
                { color: 'orange', text: '停止中' },
                { color: 'purple', text: 'メンテナンス中' },
            ]}
        />
    );
};

export default StatusLegend;