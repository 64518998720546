import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import { apiAccess } from 'utils/apiUtil';
import { getMoment } from 'utils/getMoment';
const moment = getMoment();

const BACKEND_FORMAT = 'YYYY-MM-DD';
const DISPLAY_FORMAT = 'M月D日 dddd';

const fetch = createAsyncThunk<
    PastHealthCheckStatusResponse
>(
    '/api/v1/past-health-check-status',
    async (args, thunkAPI) => {
        try {
            const url = '/api/v1/past-health-check-status';

            // リクエスト処理
            const response = await apiAccess.get<PastHealthCheckStatusResponse>(url);

            // リクエスト失敗
            if (!response.data.is_successful) {
                throw new Error('リクエストに失敗しました。');
            }

            return response.data;
        } catch (e: unknown) {
            console.error(e);
            thunkAPI.rejectWithValue(e);
            // ここでthrowすることで、promiseがrejectedになる
            throw e;
        }
    },
);

/**
 * バックエンドからのレスポンスをフロントエンドで使うモデルに変換する
 * @param response レスポンス
 * @returns モデル
 */
const convertResponseToModel = (response: PastHealthCheckStatusResponse): PastHealthCheckStatusModel => {
    return {
        pastHealthCheckStatus: response.data.past_health_check_status.map(status => ({
            title: `${moment(status.date, BACKEND_FORMAT).format(DISPLAY_FORMAT)} のサービス状況`,
            status: status.health_check_status,
        })),
    };
};

/**
 * state の初期値
 */
export const INIT_STATE: WithLoading<PastHealthCheckStatusModel> = {
    loading: true,
    pastHealthCheckStatus: [],
};

const slice = createSlice({
    name: 'pastHealthCheckStatus',
    initialState: INIT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetch.fulfilled, (state, action) => {
            return {
                ...state,
                ...convertResponseToModel(action.payload),
                loading: false,
            };
        });
        builder.addCase(fetch.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(fetch.rejected, (state) => {
            return {
                ...state,
                loading: false,
            };
        });
    },
});

export const actions = {
    ...slice.actions,
    fetch,
};

export const reducer = slice.reducer;
