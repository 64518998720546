import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'flex/hooks';
import { Actions } from 'flex/actions';
import StatusTemplate from 'components/templates/StatusTemplate';
import { useUrlParameters } from 'utils/hooks/useUrlParameters';

const StatusPage: React.VFC = () => {
    const dispatch = useAppDispatch();
    const params = useUrlParameters();
    const pastHealthCheckStatus = useAppSelector(state => state.pastHealthCheckStatus);
    const todayHealthCheckStatus = useAppSelector(state => state.todayHealthCheckStatus);

    useEffect(() => {
        dispatch(Actions.pastHealthCheckStatus.fetch());
        dispatch(Actions.todayHealthCheckStatus.fetch());
    }, [dispatch]);

    const healthCheckStatuses = [
        todayHealthCheckStatus.todayHealthCheckStatus,
        ...pastHealthCheckStatus.pastHealthCheckStatus,
    ];

    return (
        <StatusTemplate
            isDebug={params.debug}
            isLoading={todayHealthCheckStatus.loading || pastHealthCheckStatus.loading}
            recentStatus={todayHealthCheckStatus.recentHealthCheckStatus}
            statuses={healthCheckStatuses}
        />
    );
};

export default StatusPage;
