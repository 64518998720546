import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'flex/store';
import StatusPage from 'components/pages/StatusPage';

const App: React.VFC = () => {
    return (
        <Provider store={store}>
            <StatusPage />
        </Provider>
    );
};

export default App;
