import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import { apiAccess } from 'utils/apiUtil';

const fetch = createAsyncThunk<
    TodayHealthCheckStatusResponse
>(
    '/api/v1/today-health-check-status',
    async (args, thunkAPI) => {
        try {
            const url = '/api/v1/today-health-check-status';

            // リクエスト処理
            const response = await apiAccess.get<TodayHealthCheckStatusResponse>(url);

            // リクエスト失敗
            if (!response.data.is_successful) {
                throw new Error('リクエストに失敗しました。');
            }

            return response.data;
        } catch (e: unknown) {
            console.error(e);
            thunkAPI.rejectWithValue(e);
            // ここでthrowすることで、promiseがrejectedになる
            throw e;
        }
    },
);

/**
 * バックエンドからのレスポンスをフロントエンドで使うモデルに変換する
 * @param response レスポンス
 * @returns モデル
 */
const convertResponseToModel = (response: TodayHealthCheckStatusResponse): TodayHealthCheckStatusModel => {
    return {
        todayHealthCheckStatus: {
            title: '本日のサービス状況',
            status: response.data.today_health_check_status,
        },
        recentHealthCheckStatus: {
            code: response.data.recent_health_check_status_code,
            message: response.data.recent_health_check_status_message,
        },
    };
};

/**
 * state の初期値
 */
export const INIT_STATE: WithLoading<TodayHealthCheckStatusModel> = {
    loading: true,
    todayHealthCheckStatus: {
        title: '本日のサービス状況',
        status: [],
    },
    recentHealthCheckStatus: {
        code: 1,
        message: '',
    },
};

const slice = createSlice({
    name: 'todayHealthCheckStatus',
    initialState: INIT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetch.fulfilled, (state, action) => {
            return {
                ...state,
                ...convertResponseToModel(action.payload),
                loading: false,
            };
        });
        builder.addCase(fetch.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(fetch.rejected, (state) => {
            return {
                ...state,
                loading: false,
            };
        });
    },
});

export const actions = {
    ...slice.actions,
    fetch,
};

export const reducer = slice.reducer;
