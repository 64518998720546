import React, { useState } from 'react';
import BaseTemplate from './BaseTemplate';
import Button from 'components/atoms/Button';
import RecentStatus from 'components/organisms/RecentStatus';
import ServiceStatus from 'components/organisms/ServiceStatus';
import StatusLegend from 'components/organisms/StatusLegend';

type Props = {
    isDebug: boolean,
    isLoading: boolean,
    recentStatus: TodayHealthCheckStatusModel['recentHealthCheckStatus']
    statuses: {
        title: string,
        status: HourHealthCheckStatus[],
    }[],
}

const StatusTemplate: React.VFC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <BaseTemplate>
            <div className='w-4/5 mx-auto my-20 max-w-screen-lg'>
                <RecentStatus
                    className='mb-8 box-border'
                    isLoading={props.isLoading}
                    code={props.recentStatus.code}
                    message={props.recentStatus.message}
                />

                <StatusLegend className='mb-4 justify-end' />

                {props.statuses.map((status, i) =>
                    i < 1 || isOpen
                        ? <ServiceStatus
                            key={status.title}
                            className='mb-8'
                            status={status.status}
                            title={status.title}
                            isDebug={props.isDebug}
                            isLoading={props.isLoading}
                        />
                        : undefined,
                )}

                <div className='text-center'>
                    <Button
                        onClick={() => setIsOpen(state => !state)}
                        title={isOpen ? '閉じる' : 'さらに表示'}
                    />
                </div>

            </div>
        </BaseTemplate>
    );
};

export default StatusTemplate;