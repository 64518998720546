import axios, { AxiosRequestConfig } from 'axios';

/**
 * axios がリクエストを送る前に要求を処理するメソッドを作成する
 * @param timeout タイムアウト時間[ms]
 * @returns AxiosRequestConfig
 */
const createOnFulfilled = (timeout: number) => (config: AxiosRequestConfig) => {
    config.headers = {'Content-Type':'application/json'};
    config.timeout = timeout;
    config.responseType = 'json';
    config.baseURL = '/';
    config.withCredentials = true;
    return config;
};

/**
 * 通常の通信を行なう
 */
const apiAccess = axios.create({});

apiAccess.interceptors.request.use(
    createOnFulfilled(30000),
    (error) => Promise.reject(error),
);

/**
 * タイムアウトしない通信を行なう
 */
const apiLongTimeoutAccess = axios.create({});

apiLongTimeoutAccess.interceptors.request.use(
    createOnFulfilled(0),
    (error) => Promise.reject(error),
);


export { apiAccess, apiLongTimeoutAccess };
