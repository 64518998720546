
type UrlParameters = {
    debug: boolean,
};


/**
 * クエリ文字列を Object(Key をパラメーター, Value を値として持つ) にしたものを返す
 */
export const useUrlParameters = (): UrlParameters => {
    const searchParams = new URLSearchParams(window.location.search);

    return {
        debug: searchParams.get('debug') !== null,
    };
};
