import React from 'react';
import classNames from 'classnames';
import { CloudyLGate } from 'components/atoms/Logo';

const FooterLogo: React.VFC<{ className: string }> = (props) => {
    return (
        <div className={classNames(
            'relative bg-lg-blue',
            props.className,
        )}>
            <CloudyLGate height='90%' className='absolute bottom-0 left-1/2 transform -translate-x-1/2' />
        </div>
    );
};

export default FooterLogo;