import React from 'react';
import classNames from 'classnames';
import ColorCircle from 'components/atoms/ColorCircle';

type Props = {
    className?: string,
    data: {
        color: HealthCheck.Colors,
        text: string,
    }[]
};

const Legend: React.VFC<Props> = (props) => {
    return (
        <div className={classNames('flex flex-row', props.className)}>
            {props.data.map((data, i) => (
                <div
                    key={i}
                    className={classNames(
                        'flex flex-row',
                        { 'mr-4': i !== props.data.length - 1 },
                    )}
                >
                    <ColorCircle
                        className='mr-2'
                        {...{ [data.color]: true }}
                    />
                    <div>{data.text}</div>
                </div>
            ))}
        </div>
    );
};

export default Legend;