import React from 'react';
import classNames from 'classnames';

type ColorProps = Partial<Record<HealthCheck.Colors, boolean>>;

type Props = {
    className?: string,
    time?: number,
} & ColorProps;

const HealthCheckScale: React.VFC<Props> = (props) => {
    if (props.time !== undefined && (props.time < 0 || 24 < props.time)) return <></>;

    return (
        <div className={classNames(
            'flex flex-col',
            { 'w-9': props.time !== 23 },
        )}>
            <div className={classNames(
                'h-16 w-6',
                { 'bg-gray-100': props.gray || !isSettingColor(props) },
                { 'bg-lg-green': props.green },
                { 'bg-lg-orange': props.orange },
                { 'bg-lg-yellow': props.yellow },
                { 'bg-lg-purple': props.purple },
                props.className,
            )} />
            {
                props.time === undefined ?
                    undefined :
                    <div>{props.time % 4 === 0 ? `${props.time}時` : ''}</div>
            }
        </div>
    );
};

/**
 * 色に関する設定がされているかを確認する
 * @returns 設定済: true, 未設定: false
*/
const isSettingColor = (props: ColorProps) => {
    const keies = Object.keys(props) as (keyof ColorProps)[];

    return keies.some(key => {
        switch (key) {
            case 'gray':
                return props.gray;
            case 'green':
                return props.green;
            case 'orange':
                return props.orange;
            case 'yellow':
                return props.yellow;
            case 'purple':
                return props.purple;
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _nevercheck: never = key;
            }
        }
        return false;
    });
};

export default HealthCheckScale;
