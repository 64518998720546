export const codeToColor = (code: HealthCheck.Codes): HealthCheck.Colors => {
    switch (code) {
        case 0:
            return 'gray';
        case 1:
            return 'green';
        case 2:
            return 'orange';
        case 3:
            return 'yellow';
        case 4:
            return 'purple';
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _never_used: never = code;
        }
    }
    return 'green';
};

export const codeToColorEvent = (code: HealthCheck.Codes): HealthCheck.Colors => {
    switch (code) {
        case 0:
            return 'gray';
        case 1:
            return 'green';
        case 2:
            return 'orange';
        case 3:
            return 'purple';
        case 4:
            return 'green';
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _never_used: never = code;
        }
    }
    return 'green';
};