import React from 'react';
import HealthCheckScale from 'components/atoms/HealthCheckScale';
import Tooltip from 'components/atoms/Tooltip';
import ColorCircle from 'components/atoms/ColorCircle';
import { codeToColor, codeToColorEvent } from 'utils/codeToColor';

type Props = {
    isDebug?: boolean,
    isLoading?: boolean,
    status: HourHealthCheckStatus[],
}

const ServiceTimeline: React.VFC<Props> = ({
    isDebug = false,
    isLoading = false,
    status,
}) => {
    if (isLoading) return <SkeltonTimeline />;

    return (
        <div className='flex flex-row justify-between'>
            {status.map((status, i) => {
                const tooltipContent = createTooltipContent(status);
                const colorProps = {
                    [codeToColor(status.code)]: true,
                };

                if (tooltipContent === undefined || (!isDebug && colorProps.green)) {
                    return <HealthCheckScale key={i} time={i} {...colorProps} />;
                } else {
                    return (
                        <Tooltip
                            key={i}
                            content={tooltipContent}
                        >
                            <HealthCheckScale time={i} {...colorProps} />
                        </Tooltip>
                    );
                }
            })}
        </div>
    );
};

const createTooltipContent = (status: HourHealthCheckStatus) => {
    if (status.events.length === 0) return undefined;

    return status.events.map(event => (
        <div
            key={event.time}
            className='flex flex-row mb-4 '
        >
            <ColorCircle
                className='mr-2'
                {...{ [codeToColorEvent(event.code)]: true }}
            />
            <div className='mr-2'>{event.time}</div>
            <div className='mr-2'>{event.message}</div>
        </div>
    ));
};

const SkeltonTimeline = () => {
    return (
        <div className='flex flex-row justify-between'>
            {[...Array(24)].map((_, i) => {
                return <HealthCheckScale key={i} className='animate-pulse' />;
            })}
        </div>
    );
};

export default ServiceTimeline;
