import classNames from 'classnames';
import React from 'react';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.VFC<Props> = (props) => {
    const { title, ...restProps } = props;
    return (
        <button
            className={classNames(
                'items-center bg-lg-primary px-5 py-2 rounded-md text-white',
                { 'cursor-not-allowed opacity-50': props.disabled },
                { 'hover:bg-opacity-75': !props.disabled },
                props.className,
            )}
            {...restProps}
        >
            <div className='text-xl w-full'>
                {title}
            </div>
        </button>
    );
};

export default Button;
