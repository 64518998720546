import React from 'react';
import classNames from 'classnames';

type ColorProps = Partial<Record<HealthCheck.Colors, boolean>>;

type SizeProps = {
    sm?: boolean,
}

type Props = { className?: string } & ColorProps & SizeProps;

// 用途: 凡例など
/**
 * 色のついた円
 *
 * デフォルトサイズ: sm
 *
 * デフォルトカラー: green
 */
const ColorCircle: React.VFC<Props> = (props) => {
    return (
        <div className={classNames(
            'rounded-lg my-auto',
            { 'w-1 h-1 border-4': props.sm || !isSettingSize(props) },
            { 'border-lg-green bg-lg-green': props.green || !isSettingColor(props) },
            { 'border-lg-orange bg-lg-orange': props.orange },
            { 'border-lg-yellow bg-lg-yellow': props.yellow },
            { 'border-lg-purple bg-lg-purple': props.purple },
            props.className,
        )} />
    );
};

/**
 * サイズに関する設定がされているかを確認する
 * @returns 設定済: true, 未設定: false
 */
const isSettingSize = (props: SizeProps) => {
    const keies = Object.keys(props) as (keyof SizeProps)[];

    return keies.some(key => {
        switch (key) {
            case 'sm':
                return props.sm;
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _nevercheck: never = key;
            }
        }
        return false;
    });
};

/**
 * 色に関する設定がされているかを確認する
 * @returns 設定済: true, 未設定: false
 */
const isSettingColor = (props: ColorProps) => {
    const keies = Object.keys(props) as (keyof ColorProps)[];

    return keies.some(key => {
        switch (key) {
            case 'gray':
                return props.gray;
            case 'green':
                return props.green;
            case 'orange':
                return props.orange;
            case 'yellow':
                return props.yellow;
            case 'purple':
                return props.purple;
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _nevercheck: never = key;
            }
        }
        return false;
    });
};

export default ColorCircle;