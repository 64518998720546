import React, { useEffect, useRef, useState } from 'react';

type Props = {
    className?: string,
    content: React.ReactNode,
}

const ToolTipModal = ({ content, onMouseEnter, onMouseLeave }: { content: React.ReactNode, onMouseEnter: () => void, onMouseLeave: () => void }) => (
    <span className='absolute z-10 w-56 p-2 break-words text-sm leading-tight text-black transform translate-y-8 translate-x-4 bg-white shadow-lg'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        {content}
    </span>
);

const generateDelaySetStateFunc = (setState: React.Dispatch<React.SetStateAction<boolean>>, nextState: boolean) => () => {
    setTimeout(() => {
        setState(nextState);
    }, 300);
};

// children の幅が広すぎるとレイアウトが崩れると思われる.
// その時は, svg の left をいい感じに調整すればいいと思う.
const Tooltip: React.FC<Props> = (props) => {
    const { children, content } = props;
    const [isHoverChildren, setIsHoverChildren] = useState(false);
    const [isHoverModal, setIsHoverModal] = useState(false);
    const [, updator] = useState(false);
    const childrenRef = useRef<HTMLSpanElement | null>(null);

    useEffect(() => {
        updator(state => !state);
    }, [childrenRef.current?.clientHeight]);

    return (
        <div style={{
            height: `${childrenRef.current?.clientHeight}px`,
            width: `${childrenRef.current?.clientWidth}px`,
        }}>
            <div
                className='absolute'
                onMouseEnter={() => setIsHoverChildren(true)}
                onMouseLeave={generateDelaySetStateFunc(setIsHoverChildren, false)}
                ref={elm => childrenRef.current = elm}
            >
                {children}
            </div>
            {(isHoverChildren || isHoverModal) && (
                <div className='animate-fade-in-up'>
                    <ToolTipModal
                        content={content}
                        onMouseEnter={() => setIsHoverModal(true)}
                        onMouseLeave={generateDelaySetStateFunc(setIsHoverModal, false)}
                    />
                </div>
            )}
        </div>
    );
};

export default Tooltip;
