import React from 'react';
import { LGate } from 'components/atoms/Logo';

const Header: React.VFC = () => {
    return (
        <div className='p-5 shadow-md'>
            <a
                className='inline-block'
                href='https://www.info.l-gate.net/'
                target='_blank'
                rel='noreferrer'
            >
                <LGate height='38px' />
            </a>
        </div>
    );
};

export default Header;