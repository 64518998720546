import React from 'react';
import { ReactComponent as IcStatusGSvg } from 'assets/ic_status_g.svg';
import { ReactComponent as IcStatusPSvg } from 'assets/ic_status_p.svg';
import { ReactComponent as IcStatusRSvg } from 'assets/ic_status_r.svg';
import { ReactComponent as IcStatusYSvg } from 'assets/ic_status_y.svg';


export const DifficultStatus: React.VFC<GetProps<typeof IcStatusYSvg>> = (props) => {
    return <IcStatusYSvg {...props} />;
};

export const MaintenanceStatus: React.VFC<GetProps<typeof IcStatusPSvg>> = (props) => {
    return <IcStatusPSvg {...props} />;
};

export const NormalStatus: React.VFC<GetProps<typeof IcStatusGSvg>> = (props) => {
    return <IcStatusGSvg {...props} />;
};

export const StoppedStatus: React.VFC<GetProps<typeof IcStatusRSvg>> = (props) => {
    return <IcStatusRSvg {...props} />;
};
