import React from 'react';
import classNames from 'classnames';
import { getMoment } from 'utils/getMoment';
import {
    DifficultStatus,
    MaintenanceStatus,
    NormalStatus,
    StoppedStatus,
} from 'components/atoms/Icon';
import RecentStatusContents from 'components/molecules/RecentStatusContents';

const DATETIME_FORMAT = 'YYYY年M月D日 dddd HH:mm 現在';

const moment = getMoment();

type Props = {
    className?: string,
    isLoading: boolean,
    code: TodayHealthCheckStatusModel['recentHealthCheckStatus']['code'],
    message: TodayHealthCheckStatusModel['recentHealthCheckStatus']['message'],
}

const RecentStatus: React.VFC<Props> = (props) => {
    if (props.isLoading) return <></>;

    const statusInfo = createStatusInfo(props.code);
    if (statusInfo === undefined) {
        console.error(`予期しない値が取得されました. Code:${props.code}`);
        return <></>;
    }

    return (
        <RecentStatusContents
            className={classNames(
                {
                    'text-lg-green': statusInfo.green,
                    'border-lg-green': statusInfo.green,
                    'bg-lg-green': statusInfo.green,
                },
                {
                    'text-lg-yellow': statusInfo.yellow,
                    'border-lg-yellow': statusInfo.yellow,
                    'bg-lg-yellow': statusInfo.yellow,
                },
                {
                    'text-lg-orange': statusInfo.orange,
                    'border-lg-orange': statusInfo.orange,
                    'bg-lg-orange': statusInfo.orange,
                },
                {
                    'text-lg-purple': statusInfo.purple,
                    'border-lg-purple': statusInfo.purple,
                    'bg-lg-purple': statusInfo.purple,
                },
                props.className,
            )}
            date={moment().format(DATETIME_FORMAT)}
            icon={statusInfo.icon}
            sentence={props.message}
        />
    );
};

const createStatusInfo = (code: TodayHealthCheckCodes): Partial<Record<HealthCheck.Colors, boolean>> & { icon: JSX.Element } | undefined => {
    const svgProps = {
        className: 'fill-current',
        width: '55px',
    };

    switch (code) {
        case 1:
            return {
                icon: <NormalStatus {...svgProps} />,
                green: true,
            };
        case 3:
            return {
                icon: <DifficultStatus {...svgProps} />,
                yellow: true,
            };
        case 2:
            return {
                icon: <StoppedStatus {...svgProps} />,
                orange: true,
            };
        case 4:
            return {
                icon: <MaintenanceStatus {...svgProps} />,
                purple: true,
            };
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _never_check = code;
            break;
        }
    }
};

export default RecentStatus;