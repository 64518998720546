import React from 'react';
import { Uchida } from 'components/atoms/Logo';

const FooterMenu: React.VFC = () => {
    return (
        <div className='flex flex-row p-5 items-center'>
            <a
                href='https://www.uchida.co.jp/'
                target='_blank'
                rel='noreferrer'
            >
                <Uchida width='100px' />
            </a>

            <a
                className='ml-4 text-sm'
                href='https://www.uchida.co.jp/company/index.html'
                target='_blank'
                rel='noreferrer'
            >
                企業情報
            </a>
            <a
                className='ml-4 text-sm'
                href='https://www.info.l-gate.net/privacy'
                target='_blank'
                rel='noreferrer'
            >
                個人情報保護方針
            </a>
            <a
                className='ml-4 text-sm'
                href='https://www.info.l-gate.net/terms'
                target='_blank'
                rel='noreferrer'
            >
                利用規約
            </a>

            <div className='ml-auto text-gray-400 text-xs'>
                COPYRIGHT(C) UCHIDA YOKO CO., LTD. ALL RIGHTS RESERVED.
            </div>
        </div>
    );
};

export default FooterMenu;