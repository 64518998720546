import moment from 'moment';
import 'moment/locale/ja';

export const getMoment = () => {
    moment.updateLocale('ja', {
        week: {
            dow: 1,
        },
    });
    return moment;
};