import React from 'react';
import FooterLogo from 'components/molecules/FooterLogo';
import FooterMenu from 'components/molecules/FooterMenu';

const Footer: React.VFC = () => {
    return (
        <div>
            <FooterLogo className='h-48' />
            <FooterMenu />
        </div>
    );
};

export default Footer;