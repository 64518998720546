import classNames from 'classnames';
import React from 'react';

type Props = {
    className?: string,
    date: string,
    icon: React.ReactElement,
    sentence: string,
};

const RecentStatusContents: React.VFC<Props> = (props) => {
    return (
        <div
            className={classNames(
                'flex flex-row p-4 items-center',
                'border-4 rounded-lg bg-opacity-10',
                props.className,
            )}
        >
            <div className='ml-0 w-full flex flex-row'>
                <div className='mr-6 flex items-center'>
                    {props.icon}
                </div>
                <div className='mr-auto'>
                    <div className='mb-2 text-base'>
                        {props.date}
                    </div>
                    <div className='mb-2 text-xl font-bold'>
                        {props.sentence}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentStatusContents;