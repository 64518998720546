import React from 'react';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';

const BaseTemplate: React.FC = (props) => {
    return (
        <div className='min-h-full flex flex-col justify-between'>
            <div>
                <Header />

                {props.children}
            </div>

            <Footer />
        </div>
    );
};

export default BaseTemplate;