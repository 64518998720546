import React from 'react';
import { ReactComponent as FootreLogoSvg } from 'assets/footer-logo.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as UchidaSvg } from 'assets/uchida.svg';

export const LGate: React.VFC<GetProps<typeof LogoSvg>> = (props) => {
    return <LogoSvg {...props} />;
};

export const CloudyLGate: React.VFC<GetProps<typeof FootreLogoSvg>> = (props) => {
    return <FootreLogoSvg {...props} />;
};

export const Uchida: React.VFC<GetProps<typeof UchidaSvg>> = (props) => {
    return <UchidaSvg {...props} />;
};
