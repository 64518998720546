import React from 'react';
import ServiceTimeline from 'components/molecules/ServiceTimeline';

type Props = {
    className?: string,
    isDebug: boolean,
    isLoading: boolean,
    status: HourHealthCheckStatus[],
    title: string,
}

const ServiceStatus: React.VFC<Props> = (props) => {
    return (
        <div className={props.className}>
            <div className='mb-4 text-2xl font-bold'>
                {props.title}
            </div>

            <ServiceTimeline
                isDebug={props.isDebug}
                isLoading={props.isLoading}
                status={props.status}
            />
        </div>
    );
};

export default ServiceStatus;
